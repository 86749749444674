import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import TextEditor from '../Components/TextEditor';

import { getAllTags, getTriggerDescriptions } from '../Utils/NotificationUtils';
import { camelToRead } from '../Utils/DataUtils';

import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';

import MobilePreview from './MobilePreview';
import Loader from './Loader';

// Look for any of the passed tags in the given message.
const matchTags = (message, tags) => {
  const getTagRx = (tag) => new RegExp('[[](' + tag + ')[\\]]', 'gm');

  return tags.map((tag) => message.match(getTagRx(tag))).map((matched, i) => [tags[i], !!matched]);
}

export default function MessageEditor({ notification, handleSaveNotification, productData }) {
  const [triggers, setTriggers] = useState({});
  const [allTags, setAllTags] = useState([]);

  const [openMobilePreview, setOpenMobilePreview] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [newNotification, setNewNotification] = useState(notification);
  const [tagMatches, setTagMatches] = useState([]);

  const [quillValue, setQuillValue] = useState({
      editorHtml: notification?.message || '',
      title: notification?.subject || "",
      toggle: false,
      error: null
  });

  // TODO: What was this for?
  useEffect(() => document.querySelector(".MuiDialog-container").removeAttribute('tabindex'))

  useEffect(() => {
    // Setup the list of all possible tags which can be included in notification text.
    getAllTags().then(setAllTags).catch(console.error);

    // Similarly setup a list of all possible triggers.
    getTriggerDescriptions().then(setTriggers).catch(console.error);
  }, []);

  useEffect(() => {
    if (newNotification.notificationType !== 'text') {
      setTagMatches(matchTags(quillValue.editorHtml, allTags));
    } else {
      setTagMatches(matchTags(newNotification.message, allTags));
    }
  }, [allTags, newNotification.message, quillValue.editorHtml, newNotification.notificationType]);

  // TODO: This is called for everything updating almost, make it make sense.
  const handleChange = (name) => (event) => {
    const value = event.target.value;
    console.info(`change ${name}='${value}'`)

    if (name.startsWith('push.')) {
        setNewNotification({
            ...newNotification,
            userInfo: {
                ...newNotification.userInfo,
                [name.split('.')[1]]: value
            }
        });
    } else if (name.indexOf('.') > -1) {
      setNewNotification({
        ...newNotification,
        trigger: {
          ...newNotification.trigger,
          [name.split('.')[1]]: value
        }
      });
    } else if (name === 'survey') {
        setNewNotification({
          ...newNotification,
          associatedLink: value
        });
    } else if (name === 'onOccasion') {
        setNewNotification({
            ...newNotification,
            onOccasion: (value === 'occasion')
        });
    } else if (event.target.hasOwnProperty('checked')) {
      setNewNotification({
        ...newNotification,
        [name]: event.target.checked
      });
    } else {
      setNewNotification({
        ...newNotification,
        [name]: value
      });
    }
  };

  useEffect(() => console.info('notification:', newNotification), [ newNotification ]);

  const handleSave = () => {
    const hasTitle = [ 'push', 'email' ].includes(newNotification.notificationType);

    if (hasTitle) {
      if (quillValue.error) {
        setShowError(true);
      } else if (!quillValue?.title) {
        alert('No title/subject!');
      } else {
        handleSaveNotification({
          ...newNotification,
          message: quillValue.editorHtml,
          subject: quillValue.title,
          actionButton: false,
        });
      }
    } else {
      handleSaveNotification(newNotification);
    }
  };

  const handleCancel = () => handleSaveNotification(null);

  // Extracting surveys from productData.externalLinks
  const surveys = productData.externalLinks?.filter((link) => link.service === 'surveymonkey' && link.identifier)
        ?.map((link) => ({ name: link.meta.name, id: link.identifier })) || [];

  const descriptor = triggers[newNotification.trigger.type];

  if (!(descriptor && newNotification)) {
      return <div>Internal Error: Please Reload the Page.</div>;
  }

  // Length of the second line in the grid (based on the number of things that need to appear)
  const fieldLength = 12 / (!!descriptor.field + !!descriptor.value + !!descriptor.value1 + !!surveys?.length);

  const renderMobilePreview = () => {
      if (!openMobilePreview) { return null; }

      return <MobilePreview actionButton={false} quillValue={quillValue}
        setOpenMobilePreview={setOpenMobilePreview}
        openMobilePreview={openMobilePreview}>
      </MobilePreview>;
  }

  const renderErrorDialog = () => {
    return <Dialog open={showError} onClose={() => setShowError(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ textAlign: "center", fontSize: "1.3rem", padding: "3rem" }}>
          {quillValue.error}
        </DialogContentText>
      </DialogContent>
    </Dialog>;
  }

  const renderActiveSwitch = () => {
    return <Switch checked={!!newNotification.active} onChange={handleChange('active')}
      value={newNotification.active} inputProps={{ 'aria-label': 'secondary checkbox' }} />
  };

  const renderTextField = () => {
    switch (newNotification.notificationType)
    {
        case 'text': return (<>
            <br/>
            <TextField multiline rows="6" fullWidth rowsMax="32" label="Message" size="small" variant="outlined"
              value={newNotification.message} onChange={handleChange('message')}></TextField>
          </>);
        case 'email': 
        case 'push':
          return (<>
            <TextField fullWidth label="Subject" value={quillValue.title} style={{ marginBottom: 15, marginTop: 15 }} variant="outlined"
              required onChange={(e) => { e.persist(); setQuillValue((prev) => ({ ...prev, title: e.target?.value })) }} />
            <TextEditor setOpenMobilePreview={setOpenMobilePreview} setQuillValue={setQuillValue}
              quillValue={quillValue} setLoading={setLoading} />
          </>);
        default: /* esli*t is stupid */ return null;
    }
  };

  const renderTags = () => tagMatches.map(([key, match]) => (<>
    <Tooltip style={{ marginRight: 6, marginTop: 10 }} title={`[${key}]`} key={key}>
      <Chip variant="outlined" key={key} color={match ? 'secondary' : 'primary'} size="small" onClick={() => {
          if ([ 'email', 'push' ].includes(newNotification.notificationType)) {
            setQuillValue((prev) => ({ editorHtml: prev.editorHtml, ...prev, toggle: !prev.toggle, tag: `[${key}]` }));
          } else {
            setNewNotification({ ...newNotification, message: newNotification.message + `[${key}]` });
          }
      }} label={camelToRead(key)} />
    </Tooltip>
  </>));

  const triggerItems = () => Object.keys(triggers).sort().map((trigger) => (
    <MenuItem key={trigger} value={trigger}>{triggers[trigger].description}</MenuItem>
  ));

  const renderPushInfo = () => {
      if (newNotification.notificationType !== 'push') { return null; }

      // Allow custom push keys to be set on a per-product basis.
      if (productData?.pushFields?.length)
      {
          // Split into something that will fit nicely on the screen
          const triples = productData.pushFields.reduce((acc, _, idx, arr) => {
              if (!(idx % 3)) { acc.push(arr.slice(idx, idx + 3)); }
              return acc;
          }, []);

          // And put it on the screen
          return (<>
            {triples.map((arr) => {
              const len = 12 / arr.length;

              return (arr.map((ent) => (<Grid item xs={len}>
                <TextField fullWidth label={camelToRead(ent)} onChange={handleChange(`push.${ent}`)} />
              </Grid>)));
            })}
          </>);
      }

      return <>
        <Grid item xs={6}><TextField fullWidth label="Open Link" onChange={handleChange('push.link')} /></Grid>
        <Grid item xs={6}><TextField fullWidth label="Show Image" onChange={handleChange('push.image')} /></Grid>
      </>
  };

  const renderField = () => {
      if (!descriptor.field) { return null; }

      return <Grid item xs={fieldLength}>
        <TextField select fullWidth value={newNotification.trigger.field} label="Field" onChange={handleChange('trigger.field')}>
          {descriptor.field.map((dataKey) => (
              <MenuItem key={dataKey} value={dataKey}>{camelToRead(dataKey)}</MenuItem>)
          )}
          <MenuItem value={''}>None</MenuItem>
        </TextField>
      </Grid>;
  }

  const renderValue = (one) => {
      const field = 'value' + (one ? '1' : '');
      if (!descriptor[field]) { return null; }

      return <Grid item xs={fieldLength}>
          <TextField fullWidth type={descriptor[field]} label={'Value' + (one ? ' 2' : '')}
            value={newNotification.trigger[field]} onChange={handleChange(`trigger.${field}`)}>
          </TextField>
        </Grid>;
  };

  const renderSurveys = () => {
      if (!surveys?.length) { return null; }

      return <Grid item xs={fieldLength}>
          <TextField select fullWidth value={newNotification.associatedLink} label="Linked Survey" onChange={handleChange('survey')}>
            {surveys.map((survey, index) => (
              <MenuItem key={index} value={survey.id}>{survey.name ? survey.name : survey.id}</MenuItem>
            ))}
            <MenuItem value={''}>None</MenuItem>
          </TextField>
        </Grid>
  };

  if (!triggers || !allTags) { return <div>Loading...</div>; }

  return <div>
    {renderMobilePreview()}
    <Loader show={loading} />
    {renderErrorDialog()}
    <FormControlLabel control={renderActiveSwitch()} label="Active"/>
    {renderTextField()}
    <Grid container spacing={1} direction="row" justifyContent="flex-start">
      {/* List of tags used in this notification */}
      <Grid item xs={12}>{renderTags()}</Grid>
      {/* Notification Type (Method) */}
      <Grid item xs={4}>
        <TextField fullWidth select label="Type" value={newNotification.notificationType} onChange={handleChange('notificationType')}>
          <MenuItem value={'email'}>Email</MenuItem>
          <MenuItem value={'text'}>Text</MenuItem>
          <MenuItem value='push'>Push Notification</MenuItem>
        </TextField>
      </Grid>
      {/* Notification Trigger */}
      <Grid item xs={5}>
        <TextField fullWidth select label="Trigger" value={newNotification.trigger.type} onChange={handleChange('trigger.type')}>
          {triggerItems()}
        </TextField>
      </Grid>
      {/* Occasion Modifier */}
      <Grid item xs={3}>
        <TextField fullWidth select label="Modifier" value={newNotification.onOccasion ? 'occasion' : 'none'} onChange={handleChange('onOccasion')}>
          <MenuItem value={'none'}>None</MenuItem>
          <MenuItem value={'occasion'}>Occasion Based</MenuItem>
        </TextField>
      </Grid>
      {/* Selection for notification field. */}
      {renderField()}
      {/* Field for notification value */}
      {renderValue(false)}
      {/* Field for notification value1 */}
      {renderValue(true)}
      {/* Selection for associating a survey with this notification */}
      {renderSurveys()}
      {/* Special behaviors/configuration for push notifications */}
      {renderPushInfo()}
      <Grid item xs={6}><Button style={{ marginTop: 4, marginBottom: 4 }} fullWidth color="secondary" variant="contained" onClick={handleSave}>Save</Button></Grid>
      <Grid item xs={6}><Button style={{ marginTop: 4, marginBottom: 4 }} fullWidth color="primary" onClick={handleCancel}>Cancel</Button></Grid>
    </Grid>
  </div>;
}
