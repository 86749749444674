import React, {useState, useEffect} from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

import Networker from '../Utils/Networker';

const getNotifications = (id) => Networker.get({ root: 'notifications', query: { productIds: [ id ] } });
const saveProduct = (prod) => Networker.put({ root: 'products', body: prod });

export default function ExternalLinkEditor({ prod, open, handleClose }) {
  const [ notifications, setNotifications ] = useState([]);
  const [ externalLinks, setExternalLinks ] = useState([]);

  const handleSave = () => {
    saveProduct({
        externalLinks: externalLinks,
        _id: prod._id
    }).then(() => window.alert('Saved!')).catch((err) => window.alert(err));
  };

  useEffect(() => {
      if (!prod?._id) { return; }

      getNotifications(prod._id).then((res) => setNotifications(res.body)).catch(console.error);
      setExternalLinks(prod.externalLinks);
  }, [ prod ]);

  const handleEdit = (e) => {
    const id = e.target.id ?? e.target.name;

    const [ index, field ] = id.split(' ');
    const idx = Number.parseInt(index);

    // React doesn't let us modify state so I have to do things like this.
    setExternalLinks(externalLinks.map((link, i) => {
        if (idx !== i) { return link; }

        if (field === 'service') {
            return {
                service: e.target.value,
                identifier: link.identifier,
                meta: link.meta
            };
        } else if (field === 'identifier') {
            return {
                service: link.service,
                identifier: e.target.value,
                meta: link.meta
            };
        } else {
            return {
                service: link.service,
                identifier: link.identifier,
                meta: {
                    ...link.meta,
                    [field]: e.target.value
                }
            };
        }
    }));
  };

  // Add a blank field by default.
  const handleAdd = () => {
    setExternalLinks(externalLinks.concat({
      service: 'surveymonkey',
      identifier: '',
      meta: {
          cancels: '',
          name: ''
      }
    }));
  };

  const handleRemove = (idx) => {
    const newLinks = Object.assign([], externalLinks);
    newLinks.splice(idx, 1);
    setExternalLinks(newLinks);
  };

  const handleSync = async () => {
    try {
      const result = await Networker.post({
        root: 'links',
        inner: `/surveymonkey/${prod._id}/update`
      });

      window.alert('Success\nSynced ' + result.body?.new + ' new results\nMatched ' + result.body?.all + ' existing');
    } catch (e) {
      console.error(e);
      window.alert('Sync failed');
    }
  };

  if (!prod) { return <div>Loading...</div>; }

  const renderLink = ({ service, identifier, meta }, idx) => (<>
    <Grid container item xs={12} key={idx} alignItems="center" spacing={1}>
      <Grid item xs={12} md={2}>
        <TextField id={idx + ' service'} disabled select label="Service" SelectProps={{ name: 'service', id: 'service-type' }} value={service}>
          <MenuItem value="surveymonkey">SurveyMonkey</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} md={3}>
        <Tooltip title="This field is optional and will be shown on the connection statistics page">
          <TextField id={idx + ' name'} value={meta?.name} onChange={handleEdit} label="Survey Name" fullWidth />
        </Tooltip>
      </Grid>
      <Grid item xs={12} md={2}>
        <Tooltip title="This can be found on the survey monkey website under 'My Surveys': the HTML 'id' of the row can be accessed by pressing F12 and clicking 'Inspect Document'">
          <TextField id={idx + ' identifier'} value={identifier} onChange={handleEdit} label="Survey ID" fullWidth />
        </Tooltip>
      </Grid>
      <Grid item xs={12} md={4}>
        <Tooltip title="Cancel the selected notification when this survey is completed">
          <TextField id={idx + ' cancels'} select label="Cancels" onChange={handleEdit} SelectProps={{ name: idx + ' cancels' }} value={meta?.cancels ?? ''} fullWidth>
            <MenuItem value={''} id={idx + ' cancels'}>None</MenuItem>
            {notifications.map(({ _id, trigger }) => <MenuItem value={_id} id={idx + ' cancels'}>{trigger.type} ({_id})</MenuItem>)}
          </TextField>
        </Tooltip>
      </Grid>
      <Grid item xs={12} md={1}>
        <Tooltip title={`Remove ${meta?.name || 'this survey'}`}>
          <Button color="secondary" onClick={() => handleRemove(idx)} fullWidth>Remove</Button>
        </Tooltip>
      </Grid>
    </Grid>
  </>);

  return <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
    <DialogTitle id="form-dialog-title">Set up external survey link for ({prod.productId})</DialogTitle>
    <DialogContent>
      <Grid container alignItems="center" spacing={2}>
        {externalLinks.map(renderLink)}
        <Button onClick={handleAdd}>+ Add Link</Button>
      </Grid>
    </DialogContent>
    <DialogActions>
      {/* Bad button! Automated webhooks have replaced this functionality. */}
      {/* Tyler agrees, but he may want to push it sometimes. */}
      {<Tooltip title="Ping the survey monkey API for new responses. Warning: this action is rate limited">
        <Button disabled={externalLinks.length < 1} color="secondary" onClick={handleSync}>Sync All</Button>
      </Tooltip>}
      <Button onClick={handleClose} color="secondary" variant="contained">Close</Button>
      <Button onClick={handleSave} color="primary" variant="contained">Save</Button>
    </DialogActions>
  </Dialog>;
}
