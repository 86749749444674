import React, { useEffect, useState } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Tooltip from '@material-ui/core/Tooltip';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListSubheader from '@material-ui/core/ListSubheader';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';

import Networker from '../Utils/Networker';
import { not, intersection } from '../Utils/DataUtils';

const auditProduct = (prodId) => Networker.get({ root: 'products', inner: 'audit/' + prodId });
const saveProduct = (prod) => Networker.put({ root: 'products', body: prod });

const getAllSUOrganizations = () => Networker.get({ root: 'organizations', inner: 'all', cache: true });

export default function ProductEditor({ prod, open, handleClose, middleware, isSuperUser, setDensity, density }) {
    const [activeMiddleware, setActiveMiddleware] = useState([]);
    const [newSensor, setNewSensor] = useState(prod ? (prod.sensor ? (prod.sensor._id || prod.sensor) : '') : '');
    const [checked, setChecked] = useState([]);
    const [inactiveMiddleware, setInactiveMiddleware] = useState([]);
    const [middlewareWasModified, setMiddlewareModified] = useState(false);
    const [orgObject, setOrgObj] = useState({});
    const [orgs, setOrgs] = useState([]);
    const [allOrgs, setAllOrgs] = useState([]);
    const [Sensors, setSensorList] = useState([]);
    const [isDensity, setIsDensity] = useState({ dens: false, data: {} });

    useEffect(() => {
        if (isSuperUser)
        {
            getAllSUOrganizations().then(res => { setAllOrgs(res.body); });

            Networker.get({ root: 'admin', inner: 'sensors' }).then(({ body }) => {
                setSensorList(body);
            }).catch((err) => {
                console.error(err);
            });
        }
    }, [isSuperUser])

    useEffect(() => {
        setNewSensor(prod ? (prod.sensor ? (prod.sensor._id || prod.sensor) : '') : '');
        setIsDensity({ dens: false });
        setDensity(prod?.density || 1)
    }, [prod, setDensity])

    const handleOrgToggle = id => () => {
        let orgsToSearch = Object.assign([], orgs.length > 0 ? orgs : prod.organizations);
        let newOrgs = [];

        const idx = orgsToSearch.indexOf(id);

        if (idx > -1) {
            newOrgs = orgsToSearch;
            newOrgs.splice(idx, 1);
        } else {
            newOrgs = orgsToSearch.concat(id);
        }

        if (newOrgs.length < 1) {
            window.alert('Products must have at least 1 organization!');
        } else {
            setOrgs(newOrgs);
        }
    };

    const handleAudit = () => {
        if (window.confirm('Are you sure you want to audit this product?\n' +
            'This can be a very long process (minutes to hours) and can result in data being corrupted or replaced\n' +
            'The product should not be used during an audit, though it is technically still safe to do so'))
        {
            auditProduct(prod._id).then(res => { window.alert('Product audit started!'); }).catch(err => { console.error(err); })
        }
    }

    useEffect(() => {
        const newOrgObject = {};

        if (orgs && orgs.length > 0) {
            orgs.forEach(org => { newOrgObject[org] = true; });
        } else if (prod) {
            prod.organizations.forEach(org => { newOrgObject[org] = true; });
        }

        setOrgObj(newOrgObject);
    }, [orgs, prod])

    useEffect(() => {
        if (middleware.length > 0 && prod)
        {
            const active = middleware.filter(mid => prod.middleware.includes(mid._id)).sort((a, b) => {
                return prod.middleware.indexOf(a._id) - prod.middleware.indexOf(b._id);
            });

            setActiveMiddleware(active);
            setInactiveMiddleware(not(middleware, active));
        }
    }, [middleware, prod]);

    const inactiveMiddlewareChecked = intersection(checked, inactiveMiddleware);
    const activeMiddlewareChecked = intersection(checked, activeMiddleware);

    const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setActiveMiddleware(activeMiddleware.concat(inactiveMiddleware));
        setInactiveMiddleware([]);
        setMiddlewareModified(true);
    };

    const handleCheckedRight = () => {
        setActiveMiddleware(activeMiddleware.concat(inactiveMiddlewareChecked));
        setInactiveMiddleware(not(inactiveMiddleware, inactiveMiddlewareChecked));
        setChecked(not(checked, inactiveMiddlewareChecked));
        setMiddlewareModified(true);
    };

    const handleCheckedLeft = () => {
        setInactiveMiddleware(inactiveMiddleware.concat(activeMiddlewareChecked));
        setActiveMiddleware(not(activeMiddleware, activeMiddlewareChecked));
        setChecked(not(checked, activeMiddlewareChecked));
        setMiddlewareModified(true);
    };

    const handleAllLeft = () => {
        setInactiveMiddleware(inactiveMiddleware.concat(activeMiddleware));
        setActiveMiddleware([]);
        setMiddlewareModified(true);
    };

    const customList = (title, items, unordered) => (
        <Paper>
            <Typography variant="h6" component="div">{title}</Typography>
                <List dense component="div" role="list" disablePadding>
                    {
                        items.map((value, idx) => {
                            let valueObj = value;

                            if (typeof value === 'string') {
                                valueObj = middleware.find(elem => elem._id === value);
                            }

                            const labelId = `transfer-list-item-${valueObj._id}-label`;

                            return (
                                <ListItem key={valueObj._id} role="listitem" alignItems="center" disableGutters={true} button onClick={handleToggle(valueObj)}>
                                    <ListItemIcon>
                                        <Checkbox checked={checked.findIndex(elem => elem._id === valueObj._id) !== -1}
                                            tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': labelId }} />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={`${unordered ? '' : idx + 1 + '.'} ${valueObj.name }`} />
                                </ListItem>
                            );
                        })
                    }
                </List>
        </Paper>
    );


    const handleSave = () => {
        prod.name = document.getElementById('product-editor-name').value;
        prod.productId = document.getElementById('product-editor-productId').value;
        prod.iconURL = document.getElementById('product-editor-iconURL').value;
        prod.density = parseFloat(document.getElementById("product-editor-density").value);
        // prod.upc = document.getElementById('product-editor-upc').value;
        prod.dataCleaned = document.getElementById('product-editor-dataCleaned').checked;
        prod.sensor = newSensor ? newSensor : prod.sensor;

        if (middlewareWasModified) {
            prod.middleware = (activeMiddleware || []).map(mid => mid._id);
        }

        if (orgs && orgs.length > 0) { // ensure org length > 0
            prod.organizations = orgs;
        }

        if ((prod.iconURL || '').length > 255)
        {
            window.alert('Icon URL must not be longer than 255 characters!\nCurrent: ' + prod.iconURL.length);
            return;
        }

        saveProduct(prod).then((res1, res2) => {
            setIsDensity({ dens: true, data: res1.body.density })
            window.alert('saved');
        }).catch((err) => { window.alert(err); })
    };

    return prod ? (
        <Dialog fullScreen open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edit {prod.name}</DialogTitle>
            <DialogContent>
                <DialogContentText>To edit your product, please fill in the fields below with the new information and click save.</DialogContentText>
                <Grid container alignItems="center" spacing={3}>
                    <Grid container item xs={12} spacing={3}>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField autoFocus fullWidth margin="dense" id="product-editor-name" label="Name" defaultValue={prod.name} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField autoFocus fullWidth margin="dense" id="product-editor-productId" label="Product Id" defaultValue={prod.productId} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField autoFocus fullWidth margin="dense" id="product-editor-iconURL" label="Icon URL" defaultValue={prod.iconURL} />
                        </Grid>
                        <Grid style={{ display: "flex", alignItems: "end" }} md={12}>
                            <Grid item xs={12} md={3} lg={2}>
                                <Select value={newSensor} fullWidth onChange={e => { setNewSensor(e.target.value); }} >
                                    {Sensors.map(label => <MenuItem key={label.name} value={label._id}> {label.name} </MenuItem>)}
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={3} lg={2}  >
                                <TextField autoFocus fullWidth type="number" id="product-editor-density" label="Density (g/mL)" defaultValue={isDensity.dens ? isDensity.data : density} style={{ marginLeft: "10px" }} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Checkbox edge="start" id="product-editor-dataCleaned" label="Data Cleaned" defaultChecked={prod.dataCleaned} style={{ marginLeft: "10px" }} margin="dense" />
                                <Tooltip title="This will cause the data quality bar to show up as platinum">
                                    <Typography display="inline">Data Cleaned</Typography>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} alignItems="center" direction="row">
                            <Grid item>
                                {customList('Inactive Middleware', inactiveMiddleware, true)}
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" alignItems="center">
                                    <Button variant="outlined" size="small" onClick={handleAllRight} disabled={inactiveMiddleware.length === 0} aria-label="move all right">≫</Button>
                                    <Button variant="outlined" size="small" onClick={handleCheckedRight} disabled={inactiveMiddlewareChecked.length === 0} aria-label="move selected right">&gt;</Button>
                                    <Button variant="outlined" size="small" onClick={handleCheckedLeft} disabled={activeMiddlewareChecked.length === 0} aria-label="move selected left">&lt;</Button>
                                    <Button variant="outlined" size="small" onClick={handleAllLeft} disabled={activeMiddleware.length === 0} aria-label="move all left">≪</Button>
                                </Grid>
                            </Grid>
                            <Grid item>{customList('Active Middleware', activeMiddleware)}</Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {isSuperUser ? (
                                <List autoFocus margin="dense" id="admins">
                                    <ListSubheader>Product Organizations</ListSubheader>
                                    {allOrgs.map(({ _id, name }) => {
                                        const labelId = `checkbox-list-label-${_id}`;

                                        return <ListItem key={_id} role={undefined} dense button onClick={handleOrgToggle(_id)}>
                                            <ListItemIcon>
                                                <Checkbox edge="start" checked={orgObject[_id] === true} tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': labelId }} />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={name} />
                                        </ListItem>;
                                    })}
                                </List>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAudit} variant="contained" color="secondary">Audit</Button>
                <Button onClick={handleClose} color="primary">Close</Button>
                <Button onClick={handleSave} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    ) : null;
}
