import React, { useState, useEffect } from 'react';

const getIframeWithLang = (text, lang) => `<iframe style="width: 100vw; height: 90vh;" frameBorder="0" src="/${text}/${lang}.html">`;

export default function Privacy()
{
    const [ isEnglish, setIsEnglish ] = useState(true);

    const lang = (label) => {
        if (isEnglish ^ label) {
            return (label ? 'English' : 'english');
        } else {
            return (label ? 'Français' : 'french');
        }
    };

    useEffect(() => {
        const lang = window.navigator.languages.join(' ') || 'en-US';
        setIsEnglish((lang.toLowerCase().indexOf('fr') === -1));
    }, []);

    return (<div>
        <button style={{ margin: 5 }} onClick={ () => setIsEnglish(!isEnglish) }>{lang(true)}</button>
        <div dangerouslySetInnerHTML={{ __html: getIframeWithLang('policies', lang(false)) }}></div>
    </div>);
}
